/**
 * Se necesita poner window load por que no reconocia el elemento a cual estara apuntado Vue
 */
import Amplify, {Auth, API, graphqlOperation } from 'aws-amplify';
import AWS from 'aws-sdk';
import Pinpoint from 'aws-sdk/clients/pinpoint';
import * as queries from '../../src/graphql/queries';
import * as queriesControl from '../../src/graphql_control/queries';

//Analythics Mobile
import aws_config from '../../src/aws-exports';

//CtrlPlus App
import aws_config_app from '../../src/aws-exports-app';

Amplify.configure(aws_config);

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: aws_config.aws_cognito_identity_pool_id
});

AWS.config.region = 'us-east-1';

const axios = require('axios');

export default  {
    name: 'AmplifyFunctions',
    crearUsuario: function(employee, form) {
        let username = employee.email;
        let password = employee.password;

        let email = employee.user.correo.replace(/\s/g, "");
        let persona_id = String(employee.user.persona_id);
        let birthdate = employee.user.fecha_nacimiento;
        let phone_numer = '+52'+employee.user.celular;
        let gender = employee.user.sexo;
        let name = employee.user.full_name;
        let picture = employee.user.avatar;
        let proyecto_id = String(employee.user.proyecto_id);

        let info = { //Todos los campos son obligatorios
            "birthdate": birthdate,
            "email": email, //cambiar correo diecto en la base
            "gender": gender,
            "name": name,
            "phone_number": phone_numer,
            "picture": picture,
            "custom:persona_id": persona_id,
            "custom:proyecto_id": proyecto_id
        };

        let infoUpdate = [
            {'Name': 'birthdate', 'Value': birthdate},
            {'Name': 'email', 'Value': email},
            {'Name': 'gender', 'Value': gender},
            {'Name': 'name', 'Value': name},
            {'Name': 'phone_number', 'Value': phone_numer},
            {'Name': 'picture', 'Value': picture},
            {'Name': 'custom:persona_id', 'Value': persona_id},
            {'Name': 'custom:proyecto_id', 'Value': proyecto_id}
        ];

        //Auth Cognito Analythics Mobile
        Auth.signUp({ //cognito
            username,
            password,
            attributes: info,
            validationData: []  //optional
        }).then(resultado => { //mysql
            this.crearUsuarioApp(employee, form);
        }).catch(error => {
            if(error.code === "UsernameExistsException"){
                this.adminNuevoPassword(username, password, aws_config).then(response => {
                    this.adminActualizarUsuario(username, infoUpdate, aws_config).then(response => {
                        this.crearUsuarioApp(employee, form);
                    });
                });
            } else {
                this.crearUsuarioApp(employee, form);
            }
        });
    },
    crearUsuarioSoporte: function(employee, form) {
        let username = employee.user.rfc;
        let password = employee.user.nss;

        let email = employee.user.correo.replace(/\s/g, "");
        let persona_id = String(employee.user.persona_id);
        let birthdate = employee.user.fecha_nacimiento;
        let phone_numer = '+52'+employee.user.celular;
        let gender = employee.user.sexo;
        let name = employee.user.full_name;
        let picture = employee.user.avatar;
        let proyecto_id = String(employee.user.proyecto_id);

        let info = { //Todos los campos son obligatorios
            "birthdate": birthdate,
            "email": email, //cambiar correo diecto en la base
            "gender": gender,
            "name": name,
            "phone_number": phone_numer,
            "picture": picture,
            "custom:persona_id": persona_id,
            "custom:proyecto_id": proyecto_id
        };

        let infoUpdate = [
            {'Name': 'birthdate', 'Value': birthdate},
            {'Name': 'email', 'Value': email},
            {'Name': 'gender', 'Value': gender},
            {'Name': 'name', 'Value': name},
            {'Name': 'phone_number', 'Value': phone_numer},
            {'Name': 'picture', 'Value': picture},
            {'Name': 'custom:persona_id', 'Value': persona_id},
            {'Name': 'custom:proyecto_id', 'Value': proyecto_id}
        ];

        Amplify.configure(aws_config);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        //Auth Cognito Analythics Mobile
        Auth.signUp({ //cognito
            username,
            password,
            attributes: info,
            validationData: []  //optional
        }).then(resultado => { //mysql
            if(typeof form !== "undefined") {
                form.submit();
            }
        }).catch(error => {
            if(error.code === "UsernameExistsException"){
                this.adminNuevoPassword(username, password, aws_config).then(response => {
                    this.adminActualizarUsuario(username, infoUpdate, aws_config).then(response => {
                        if (typeof form !== "undefined") {
                            form.submit();
                        }
                    });
                });
            } else {
                if(typeof form !== "undefined") {
                    form.submit();
                }
            }
        });
    },
    crearUsuarioSoporteApp: function(employee, form) {
        let username = employee.user.rfc;
        let password = employee.user.nss;

        let email = employee.user.correo.replace(/\s/g, "");
        let persona_id = String(employee.user.persona_id);
        let birthdate = employee.user.fecha_nacimiento;
        let phone_numer = '+52'+employee.user.celular;
        let gender = employee.user.sexo;
        let name = employee.user.full_name;
        let picture = employee.user.avatar;
        let proyecto_id = String(employee.user.proyecto_id);

        let info = { //Todos los campos son obligatorios
            "birthdate": birthdate,
            "email": email, //cambiar correo diecto en la base
            "gender": gender,
            "name": name,
            "phone_number": phone_numer,
            "picture": picture,
            "custom:persona_id": persona_id,
            "custom:proyecto_id": proyecto_id
        };

        let infoUpdate = [
            {'Name': 'birthdate', 'Value': birthdate},
            {'Name': 'email', 'Value': email},
            {'Name': 'gender', 'Value': gender},
            {'Name': 'name', 'Value': name},
            {'Name': 'phone_number', 'Value': phone_numer},
            {'Name': 'picture', 'Value': picture},
            {'Name': 'custom:persona_id', 'Value': persona_id},
            {'Name': 'custom:proyecto_id', 'Value': proyecto_id}
        ];

        Amplify.configure(aws_config_app);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config_app.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        //Auth Cognito Analythics Mobile
        Auth.signUp({ //cognito
            username,
            password,
            attributes: info,
            validationData: []  //optional
        }).then(resultado => { //mysql
            if(typeof form !== "undefined") {
                form.submit();
            }
        }).catch(error => {
            if(error.code === "UsernameExistsException"){
                this.adminNuevoPassword(username, password, aws_config_app).then(response => {
                    this.adminActualizarUsuario(username, infoUpdate, aws_config_app).then(response => {
                        this.crearUsuarioSoporte(employee, form);
                    });
                });
            } else {
                if(typeof form !== "undefined") {
                    this.crearUsuarioSoporte(employee, form);
                }
            }
        });
    },
    crearUsuarioApp: function(employee, form) {
        let username = employee.email;
        let password = employee.password;

        let email = employee.user.correo.replace(/\s/g, "");
        let persona_id = String(employee.user.persona_id);
        let birthdate = employee.user.fecha_nacimiento;
        let phone_numer = '+52'+employee.user.celular;
        let gender = employee.user.sexo;
        let name = employee.user.full_name;
        let picture = employee.user.avatar;
        let proyecto_id = String(employee.user.proyecto_id);//19483

        let info = { //Todos los campos son obligatorios
            "birthdate": birthdate,
            "email": email, //cambiar correo diecto en la base
            "gender": gender,
            "name": name,
            "phone_number": phone_numer,
            "picture": picture,
            "custom:persona_id": persona_id,
            "custom:proyecto_id": proyecto_id
        };

        let infoUpdate = [
            {'Name': 'birthdate', 'Value': birthdate},
            {'Name': 'email', 'Value': email},
            {'Name': 'gender', 'Value': gender},
            {'Name': 'name', 'Value': name},
            {'Name': 'phone_number', 'Value': phone_numer},
            {'Name': 'picture', 'Value': picture},
            {'Name': 'custom:persona_id', 'Value': persona_id},
            {'Name': 'custom:proyecto_id', 'Value': proyecto_id}
        ];

        Amplify.configure(aws_config_app);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config_app.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        //Auth Cognito CtrlPlus App
        Auth.signUp({ //cognito
            username,
            password,
            attributes: info,
            validationData: []  //optional
        }).then(resultado => { //mysql
            this.crearUsuarioSoporteApp(employee, form);
        }).catch(error => {
            if(error.code === "UsernameExistsException"){
                this.adminNuevoPassword(username, password, aws_config_app).then(response => {
                    this.adminActualizarUsuario(username, infoUpdate, aws_config_app).then(response => {
                        this.crearUsuarioSoporteApp(employee, form);
                    });
                });
            } else {
                this.crearUsuarioSoporteApp(employee, form);
            }
        });
    },
    initialConfigAWS: function(aws_configuration){
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_configuration.aws_cognito_identity_pool_id
        });

        AWS.config.region = 'us-east-1';
    },
    login: function(username, password){
        Auth.signIn(username, password)
            .then((user) => {
                console.log(user);
                console.log(user.signInUserSession.idToken.payload);
            })
            .catch(error => {
                console.log(error);
            });
    },
    verificarAtributoUsuario: function(username, password, attribute, form){
        Auth.signIn(username, password)
            .then((user) => {
                Auth.verifyUserAttribute(user, attribute).then(result => {
                    if(typeof form !== "undefined") {
                        form.submit();
                    }
                }).catch(error => {
                    console.log(error);
                });
            }).catch(error => {
            console.log(error);
        });
    },
    verificarCorreo: function (username, password, code) {
        Auth.signIn(username, password)
            .then((user) => {
                Auth.verifyUserAttributeSubmit(user, 'email', code).then(result => {

                }).catch(error => {
                    console.log(error);
                });
            }).catch(error => {
                console.log(error);
            });
    },
    confirmarUsuario: function(username, code){
        Auth.confirmSignUp(username, code)
            .then((user) => {

            })
            .catch(error => {
                console.log(error);
            });
    },
    olvidePassword: function(username){
        Auth.forgotPassword(username)
            .then( response  => {} )
            .catch( error => {} );
    },
    nuevoPassword: function(username, code, new_password){
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then((user) => {

            }).catch(error => {
            console.log(error);
        });
    },
    actualizarAtributos: function(username, password, attributes){
        Auth.signIn(username, password)
            .then((user) => {
                Auth.updateUserAttributes(user, attributes).then(result => {

                }).catch(error => {
                    console.log(error);
                });
            }).catch(error => {
            console.log(error);
        });
    },
    autoConfirmarUsuario: function (userpool_id, username) {
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});

        var params = {
            UserPoolId: userpool_id,
            Username: username,
        };

        cognitoidentityserviceprovider.adminConfirmSignUp(params, function(error, data){
            if (error){
                console.log(error, error.stack);
            }
            else{
                console.log(data);
            }
        });
    },
    deshabilitarUsuario: function(userpool_id, username){
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});

        var params = {
            UserPoolId: userpool_id,
            Username: username,
        };

        cognitoidentityserviceprovider.adminDisableUser(params, function(error, data){
            if (error){
                console.log(error, error.stack);
            }
            else{
                console.log(data);
            }
        });
    },
    habilitarUsuario: function(userpool_id, username){
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});

        var params = {
            UserPoolId: userpool_id,
            Username: username,
        };

        cognitoidentityserviceprovider.adminEnableUser(params, function(error, data){
            if (error){
                console.log(error, error.stack);
            }
            else{
                console.log(data);
            }
        });
    },
    adminNuevoPassword: async function(username, new_password, aws_configuration){
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});

        this.initialConfigAWS(aws_configuration);

        var params = {
            UserPoolId: aws_configuration.aws_user_pools_id,
            Username: username,
            Permanent: true,
            Password: new_password
        };

        await cognitoidentityserviceprovider.adminSetUserPassword(params, function(error, data){
            if (error){
                return error;
            }
            else{
                return data;
            }
        });
    },
    adminActualizarUsuario: async function(username, data, aws_configuration) {
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
        this.initialConfigAWS(aws_configuration);
        var params = {
            UserPoolId: aws_configuration.aws_user_pools_id,
            Username: username,
            UserAttributes: data
        };
        await cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function(error, data){
            if (error){
                console.log(error);
                return error;
            }
            else{
                console.log(data);
                return data;
            }
        });
    },
    adminObtenerUsuario: async function(username, aws_configuration) {
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
        this.initialConfigAWS(aws_configuration);
        var params = {
            UserPoolId: aws_configuration.aws_user_pools_id,
            Username: username
        };
        await cognitoidentityserviceprovider.adminGetUser(params, function(error, data){
            if (error){
                console.log(error);
                return error;
            }
            else{
                console.log(data);
                return data;
            }
        });
    },
    getUser: function(username) {
        this.adminObtenerUsuario(username, aws_config);
    },
    adminIniciarAuth: async function(username, password, aws_configuration) {
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
        this.initialConfigAWS(aws_configuration);
        var params = {
            AuthFlow: 'ADMIN_NO_SRP_AUTH',
            ClientId: aws_configuration.aws_user_pools_web_client_id,
            UserPoolId: aws_configuration.aws_user_pools_id,
            AuthParameters: {USERNAME: username, PASSWORD: password}
        };
        await cognitoidentityserviceprovider.adminInitiateAuth(params, function(error, data) {
            if (error){
                console.log(error);
                return error;
            }
            else{
                console.log(data);
                return data;
            }
        });
    },
    initialAuth: function(username, password) {
        this.adminIniciarAuth(username, password, aws_config);
    },
    getLastPeriod: function(period) {
        var today = new Date();
        var prior = new Date().setDate(today.getDate() - period);
        prior = new Date(prior);

        var dd = String(prior.getDate()).padStart(2, '0');
        var mm = String(prior.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = prior.getFullYear();
        return  yyyy+'-'+mm+'-'+dd;
    },
    queryAsistencias: async function(search, personId, nextToken) {
        var variables = typeof nextToken === "undefined" ? { limit: 1000 } : { limit: 1000, nextToken: nextToken };
        var filters = null;
        var parameters = null;

        Amplify.configure(aws_config);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config_app.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        return await Auth.signIn("ejecucion@controlplus.mx", "wUQyVHPFN8T7").then(function() {
            if(search !== '' && personId !== '' ) {
                filters = { personaId: personId, rolRutaId: {eq: search} };
                parameters = Object.assign(variables, filters);
                return API.graphql(
                    graphqlOperation(queries.listAsistenciasByPersonaRolRuta, parameters)).then((evt) => {
                    return evt.data.listAsistenciasByPersonaRolRuta;
                });
            } else if(search !== '') {
                filters = { rolRutaId: search };
                parameters = Object.assign(variables, filters);
                return API.graphql(
                    graphqlOperation(queries.listAsistenciasByRolRuta, parameters)).then((evt) => {
                    return evt.data.listAsistenciasByRolRuta;
                });
            } else if(personId !== '') {
                filters = { personaId: personId };
                parameters = Object.assign(variables, filters);
                return API.graphql(
                    graphqlOperation(queries.listAsistenciasByPersona, parameters)).then((evt) => {
                    return evt.data.listAsistenciasByPersona;
                });
            }
        });
    },
    queryAsistenciasControl: async function(search, personId, nextToken) {
        var variables = typeof nextToken === "undefined" ? { limit: 1000 } : { limit: 1000, nextToken: nextToken };
        var filters = null;
        var parameters = null;

        Amplify.configure(aws_config_app);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config_app.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        return await Auth.signIn("ejecucion@controlplus.mx", "wUQyVHPFN8T7").then(function() {
            if(search !== '' && personId !== '' ) {
                filters = { personaId: personId, rolRutaId: {eq: search} };
                parameters = Object.assign(variables, filters);
                console.log('antes de query');
                return API.graphql(
                    graphqlOperation(queriesControl.listAsistenciasByPersonaRolRuta, parameters)).then((evt) => {
                    return evt.data.listAsistenciasByPersonaRolRuta;
                });
            } else if(search !== '') {
                filters = { rolRutaId: search };
                parameters = Object.assign(variables, filters);
                console.log('antes de query');
                return API.graphql(
                    graphqlOperation(queriesControl.listAsistenciasByRolRuta, parameters)).then((evt) => {
                    return evt.data.listAsistenciasByRolRuta;
                });
            } else if(personId !== '') {
                filters = { personaId: personId };
                parameters = Object.assign(variables, filters);
                console.log('antes de query');
                return API.graphql(
                    graphqlOperation(queriesControl.listAsistenciasByPersona, parameters)).then((evt) => {
                    return evt.data.listAsistenciasByPersona;
                });
            }
        });
    },
    queryRespuestas: async function(search, personId, nextToken) {
        var variables = typeof nextToken === "undefined" ? { limit: 1000 } : { limit: 1000, nextToken: nextToken };
        var filters = null;
        var parameters = null;

        Amplify.configure(aws_config);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config_app.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        return await Auth.signIn("ejecucion@controlplus.mx", "wUQyVHPFN8T7").then(function() {
            if(search !== '' && personId !== '' ) {
                filters = { personaId: personId, rolRutaId: {eq: search} };
                parameters = Object.assign(variables, filters);
                return API.graphql(
                    graphqlOperation(queries.listRespuestasByPersonaRolRuta, parameters)).then((evt) => {
                    return evt.data.listRespuestasByPersonaRolRuta;
                });
            } else if(search !== '') {
                filters = { rolRutaId: search };
                parameters = Object.assign(variables, filters);
                return API.graphql(
                    graphqlOperation(queries.listRespuestasByRolRuta, parameters)).then((evt) => {
                    return evt.data.listRespuestasByRolRuta;
                });
            } else if(personId !== '') {
                filters = { personaId: personId };
                parameters = Object.assign(variables, filters);
                return API.graphql(
                    graphqlOperation(queries.listRespuestasByPersona, parameters)).then((evt) => {
                    return evt.data.listRespuestasByPersona;
                });
            }
        });
    },
    queryRespuestasControl: async function(search, personId, nextToken) {
        var variables = typeof nextToken === "undefined" ? { limit: 1000 } : { limit: 1000, nextToken: nextToken };
        var filters = null;
        var parameters = null;

        Amplify.configure(aws_config_app);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws_config_app.aws_cognito_identity_pool_id
        });
        AWS.config.region = 'us-east-1';

        return await Auth.signIn("ejecucion@controlplus.mx", "wUQyVHPFN8T7").then(function() {
            if(search !== '' && personId !== '' ) {
                filters = { personaId: personId, rolRutaId: {eq: search} };
                parameters = Object.assign(variables, filters);
                console.log('antes de query');
                return API.graphql(
                    graphqlOperation(queriesControl.listRespuestasByPersonaRolRuta, parameters)).then((evt) => {
                    return evt.data.listRespuestasByPersonaRolRuta;
                });
            } else if(search !== '') {
                filters = { rolRutaId: search };
                parameters = Object.assign(variables, filters);
                console.log('antes de query');
                return API.graphql(
                    graphqlOperation(queriesControl.listRespuestasByRolRuta, parameters)).then((evt) => {
                    return evt.data.listRespuestasByRolRuta;
                });
            } else if(personId !== '') {
                filters = { personaId: personId };
                parameters = Object.assign(variables, filters);
                console.log('antes de auth');
                return API.graphql(
                    graphqlOperation(queriesControl.listRespuestasByPersona, parameters)).then((evt) => {
                    return evt.data.listRespuestasByPersona;
                });
            }
        });
    },
    listarAsistencias: async function(nextToken, period, date){
        var curdate = date;
        if (period === 'mes') {
            curdate = this.getLastPeriod(30);
        } else if (period === 'semana') {
            curdate = this.getLastPeriod(7);
        } else if (period === 'quincena') {
            curdate = this.getLastPeriod(15);
        }

        return await Auth.signIn("ejecucion@controlplus.mx", "wUQyVHPFN8T7").then(function() {
            if(typeof nextToken === "undefined") {
                var variables = {
                    limit: 1000,
                    filter: {
                        or: [
                            { createdAt:{ ge: curdate } },
                            { updatedAt:{ ge: curdate } }
                        ]
                    }
                }
            } else {
                var variables = {
                    limit: 1000,
                    filter: {
                        or: [
                            { createdAt:{ ge: curdate } },
                            { updatedAt:{ ge: curdate } }
                        ]
                    },
                    nextToken: nextToken
                }
            }

            return API.graphql(
                graphqlOperation(queries.syncAsistencias, variables)
            ).then( (evt) => {
                return evt.data.syncAsistencias;
            });
        });
    },
    listarRespuestas: async function(nextToken, period, date){
        var curdate = date;
        if (period === 'mes') {
            curdate = this.getLastPeriod(30);
        } else if (period === 'semana') {
            curdate = this.getLastPeriod(7);
        } else if (period === 'quincena') {
            curdate = this.getLastPeriod(15);
        }
        // return await Auth.signIn("ejecucion@controlplus.mx", "wUQyVHPFN8T7").then(function() {
            if(typeof nextToken === "undefined") {
                var variables = {
                    limit: 1000,
                    filter: {
                        or: [
                            { createdAt:{ ge: curdate } },
                            { updatedAt:{ ge: curdate } }
                        ]
                    }
                }
            } else {
                var variables = {
                    limit: 1000,
                    filter: {
                        or: [
                            { createdAt:{ ge: curdate } },
                            { updatedAt:{ ge: curdate } }
                        ]
                    },
                    nextToken: nextToken
                }
            }

            return API.graphql(
                graphqlOperation(queries.syncRespuestas, variables)
            ).then( (evt) => {
                return evt.data.syncRespuestas;
            });
        // });
    },
    sendNotification: function(token, body, title, aws_configuration) {
        var messageRequest = {
            'Addresses': {
                [token]: {
                    'ChannelType' : 'GCM'
                }
            },
            'MessageConfiguration': {
                'GCMMessage': {
                    'Action': 'OPEN_APP',
                    'Body': body,
                    'Priority': 'normal',
                    'SilentPush': false,
                    'Title': title
                }
            }
        };
        this.initialConfigAWS(aws_configuration);

        //Create a new Pinpoint object.
        var pinpoint = new Pinpoint();
        var params = {
            "ApplicationId": aws_configuration.aws_mobile_analytics_app_id,
            "MessageRequest": messageRequest
        };

        // Try to send the message.
        pinpoint.sendMessages(params, function(err, data) {
            if (err) {
                console.log(err);
            }
            else {
                var status = "The message wasn't sent. Response information: ";
                if (data["MessageResponse"]["Result"][token]["DeliveryStatus"] === "SUCCESSFUL") {
                    status = "Message sent! Response information: ";
                }
                console.log(status);
                console.dir(data, { depth: null });
            }
        });
    },
    enviarNotificacion: function(token, mensaje, titulo) {
        //'This is a sample message sent from Amazon Pinpoint by using the AWS SDK for JavaScript in Node.js'
        //'Test message sent from Amazon Pinpoint.'
        this.sendNotification(token, mensaje, titulo, aws_config);
        this.sendNotification(token, mensaje, titulo, aws_config_app);
    }
};